<template>
    <div>
        <div class="container alert">
            <div class="div-close">
                <router-link :to="{ name: 'tarefas' }" id="close">X</router-link>
            </div>

            <div class="alert-image">
                <img src="../assets/img/alert-circle-green.svg" alt="imagem de alerta" />
                <h1>
                    Há <span id="alertsNumber">{{ total }}</span> Alertas de Validade na sua loja hoje. Garanta que
                    todos sejam executados!
                </h1>
            </div>
        </div>

        <div class="container footer-alert">
            <div class="ok">
                <router-link :to="{ name: 'tarefas' }" id="btnOk">OK</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            total: null,
        };
    },

    computed: {
        getTotalTarefas() {
            return this.$store.getters['tarefas/getTotalTarefas'];
        },
    },

    created() {
        this.total = this.getTotalTarefas;
    },
};
</script>

<style scoped>
.container {
    width: 100%;
    max-width: 500px;
}

.alert {
    display: block;
}

.div-close {
    display: flex;
    justify-content: flex-end;
}

#close {
    margin: 8px;
    color: var(--preto);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    cursor: pointer;
}

#close a {
    color: var(--preto);
    font-size: 15px;
}

#close:hover {
    background-color: #dbdbdb;
}

.alert-image {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.alert-image img {
    margin: 0px auto 40px;
    min-width: 200px;
    max-width: 275px;
}

.alert-image h1 {
    margin-top: 20px;
    font-weight: 400;
    padding: 0px 15px 8px;
    font-size: 1.333rem;
    line-height: 1.333rem;
}

.alert-image h1 span {
    font-size: 1.333rem;
}

.footer-alert {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 18px;
}

.ok {
    display: flex;
    width: 100%;
    place-content: center;
}

.redot-container {
    padding-bottom: 0px;
    display: flex;
    padding: 8px;
}

.redot {
    width: 8px;
    height: 8px;
    margin: 0 2px;
    border-radius: 50%;
    background-color: var(--verde);
}

#btnOk {
    cursor: pointer;
    color: var(--branco);
    min-width: 64px;
    background-color: var(--verde);
    border: none;
    border-radius: 4px;
    padding: 10px 16px;
    text-align: center;
}

#btnOk:hover {
    box-shadow: none;
    background-color: #e7514f;
}

@media (max-width: 319px) {
    .alert-image img {
        margin-bottom: 0px;
        max-width: 220px;
    }
    .alert-image h1 {
        font-size: 1rem;
    }

    .alert-image h1 span {
        font-size: 1.1rem;
        font-weight: 600;
    }
}
</style>
